import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
  Layout,
  SEO,
  Hero,
  GetInTouch,
  SuitsOverlay,
  ContactInfo,
} from '../components';
import { GoogleMap } from '../components/map/google-map';

function ContactPage() {
  const { file } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "contact-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="Contact us" />
      <Hero image={file.childImageSharp.fluid} heading="Contact Us" />
      <SuitsOverlay />
      <ContactUs />
      <GetInTouch />
    </Layout>
  );
}

function ContactUs() {
  return (
    <article className="pt-24 bg-navy">
      <div className="grid items-center gap-8 px-4 py-8 mx-auto sm:px-6 lg:px-8 md:grid-cols-2 max-w-7xl">
        <div className="flex flex-col justify-center col-span-1">
          <ContactInfo />
        </div>
        <div className="col-span-1">
          <div className="relative h-0 aspect-w-4 aspect-h-4">
            <div className="absolute inset-0 flex">
              <GoogleMap zoom={12} />
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default ContactPage;
